import React, { useState, useContext } from "react"
import { AppContext } from "../context/context"
import { Link } from "gatsby"
import { FiChevronDown } from "react-icons/fi"
import styles from "./side-nav.module.scss"

const SubNav = ({ parent, kid, link_text, location, prReviews }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLocation, setLocation] = useState(false)
  const { handleCloseNav, whereWeServiceActive, whereWeServiceSlug } =
    useContext(AppContext)
  const parentSlug =
    parent.value.length > 0 ? parent.value[0].elements.slug.value : ""
  const handleSubNav = () => {
    if (!isOpen) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }
  const checkChildElements = child_page => {
    child_page.value.forEach(({ elements }) => {
      const { page } = elements
      const [links] = page.value
      const { slug } = links.elements

      if (slug.value === location || parentSlug === location) {
        setIsOpen(true)
        setLocation(true)
        return
      }
    })
  }

  if (!isOpen && !isLocation) checkChildElements(kid)

  const getChildElements = child_page => {
    const { __typename } = child_page.value[0].elements.page.value[0]
    if (__typename === "kontent_item_service_area") {
      return (
        <ul className={isOpen ? styles.subNavList : styles.subNavClosed}>
          {child_page.value.map(({ elements, id }) => {
            const { page, link_text } = elements
            const [links] = page.value
            const { slug } = links.elements
            return (
              <li key={id}>
                <Link
                  onClick={handleCloseNav}
                  to={
                    whereWeServiceActive === "true"
                      ? `/${whereWeServiceSlug}/${slug.value}`
                      : `/${slug.value}`
                  }
                  activeClassName={styles.subLinkActive}
                  className={styles.subNav}
                >
                  {link_text.value}
                </Link>
              </li>
            )
          })}
        </ul>
      )
    } else {
      return (
        <ul className={isOpen ? styles.subNavList : styles.subNavClosed}>
          {child_page.value.map(({ elements, id }) => {
            const { page, link_text } = elements
            const [links] = page.value
            const { slug } = links.elements
            // if pestroutes reviews are off we will not return that menu item
            if (
              prReviews.value[0].codename === "no" &&
              slug.value === "reviews"
            )
              return null
            return (
              <li key={id}>
                <Link
                  onClick={handleCloseNav}
                  to={slug.value === "/" ? `${slug.value}` : `/${slug.value}`}
                  activeClassName={styles.subLinkActive}
                  className={styles.subNav}
                >
                  {link_text.value}
                </Link>
              </li>
            )
          })}
        </ul>
      )
    }
  }

  if (parentSlug !== "") {
    return (
      <li
        className={isOpen ? `${styles.linkActive} ${styles.link}` : styles.link}
      >
        <div className={styles.parent}>
          <Link
            to={`/${parentSlug}`}
            onClick={handleCloseNav}
            className={styles.parentLink}
            activeClassName={styles.parentActive}
          >
            {link_text.value}
          </Link>
          <button
            onClick={handleSubNav}
            className={styles.parentButton}
            aria-label="Drop Down Button"
          >
            <FiChevronDown />
          </button>
        </div>
        {getChildElements(kid)}
      </li>
    )
  } else {
    return (
      <li
        className={isOpen ? `${styles.linkActive} ${styles.link}` : styles.link}
      >
        <button onClick={handleSubNav} aria-label="Drop Down Button">
          <span className={styles.flex}>
            {link_text.value} <FiChevronDown />
          </span>
        </button>
        {getChildElements(kid)}
      </li>
    )
  }
}

export default SubNav
