import React, { useContext } from "react"
import { Link } from "gatsby"
import styles from "./mobile-nav.module.scss"
import { AppContext } from "../context/context"
import { FaBars } from "react-icons/fa"
import { BsCircleFill, BsEnvelopeFill } from "react-icons/bs"
const MobileNav = ({ navItems }) => {
  const { handleOpenNav, height } = useContext(AppContext)
  let navLength = navItems.length
  const { link_text, page } = navItems[navLength - 1].elements
  const { slug } = page.value[0].elements

  return (
    <aside
      className={
        height > 80 ? `${styles.bottomNav} ${styles.fixed}` : styles.bottomNav
      }
    >
      <Link to="/" className={styles.link} activeClassName={styles.linkActive}>
        <BsCircleFill /> Home
      </Link>
      <Link
        to={`/${slug.value}`}
        className={styles.link}
        activeClassName={styles.linkActive}
      >
        <BsEnvelopeFill /> {link_text.value}
      </Link>
      <span className={styles.link}>
        <FaBars onClick={handleOpenNav} />
        Menu
      </span>
    </aside>
  )
}

export default MobileNav
